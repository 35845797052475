import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import List from '../../components/List'
import LayoutListView from '../../components/LayoutListView'
import Block from '../../components/Block'
import missionSeek from '../../images/mission-seek-square.png'

// @TODO - check if env variable and siteMetadata come through
const EventsTraining = ({ location }) => {
  const configID = '6OjwlZGvzoUWTJ9UKnCxq0'
  const queryData = useStaticQuery(graphql`
    {
      allContentfulTraining(sort: { fields: title, order: ASC }) {
        edges {
          node {
            id
            title
            shortName
            description {
              json
            }
            profileImage {
              file {
                url
              }
            }
            slug
            brief
            createdAt(formatString: "MMMM Do YYYY")
          }
        }
      }
    }
  `)
  const TYPE = 'Training'
  const IMAGE_HEIGHT = 100
  const data = queryData.allContentfulTraining.edges.map(obj => ({
    id: obj.node.id,
    title: `${obj.node.title} (${obj.node.shortName})`,
    body: obj.node.brief,
    image: obj.node.profileImage,
    imageHeight: IMAGE_HEIGHT,
    slug: `/events/training/${obj.node.slug}`,
    category: null,
    type: TYPE
  }))

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <List data={data} itemType="subMenu" className="items-container" />
      <div className="row" style={{ paddingTop: '60px' }}>
        <div className="col-md-3">
          <a href="https://www.missionseek.com.au/events/">
            <img className="photo-effect" src={missionSeek} alt="MissionSeek" />
          </a>
        </div>
        <div className="col-md-9">
          <Block contentfulID="6vLs4iPC46wiJMWQSu9z04" hLevel="" />
        </div>
      </div>
    </LayoutListView>
  )
}

export default EventsTraining

EventsTraining.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
